<template>
  <div
    class="resizable-wrapper-content"
    v-resizable-modal="'INSPECTION_DETAILS_VIEW_SECTION'"
    @onCloseModal="closeModal"
  >
    <button
      v-if="false"
      class="btn close"
      @click="scrollToFirstUntouchedField"
    ></button>
    <div class="resizable-wrapper-content-header">
      <div class="resizable-wrapper-content-header-left">
        <h1>{{ sectionForm.name }}</h1>
      </div>
      <div class="resizable-wrapper-content-header-right">
        <div class="custom-loading" v-if="loading">
          <div class="custom-loading-icon"></div>
          <p>{{ $t("COMMON.LOADING") }}</p>
        </div>
        <button class="btn expand">
          <i class="fal fa-expand-arrows"></i>
        </button>
        <button class="btn close" @click="closeModal">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
    <div class="resizable-wrapper-content-body" ref="sectionModalBody">
      <div class="inspection-details">
        <div class="inspection-details-buttons">
          <base-button
            :disabled="settingLoading || loading"
            v-for="section in inspection.inspectionForm.inspectionFormSections.filter(
              (item) => canShowElement(item, inspectionData)
            )"
            :key="section.id"
            :title="section.name"
            :id="section.id"
            @click="changeSection(section)"
            v-bind:class="{ active: section.id == sectionForm.id }"
          >
            <i class="fal fa-camera"></i>
            <span>
              <el-tooltip
                class="item"
                :content="section.name"
                placement="top-center"
              >
                <img
                  v-if="section.icon"
                  :src="section.icon"
                  :alt="section.name"
                />
              </el-tooltip>
            </span>
            <div
              class="check"
              v-if="isSectionFillComplete(section, inspectionData)"
            >
              <i class="fal fa-check"></i>
            </div>
            <div
              class="bad"
              v-if="
                isSectionTouched(section, inspectionData) &&
                !isSectionFillComplete(section, inspectionData)
              "
            >
              <i class="fal fa-times"></i>
            </div>
            <div
              class="section-score"
              v-if="isSectionTouched(section, inspectionData)"
            >
              {{ getSectionScore(section) }}%
            </div>
          </base-button>
        </div>

        <div class="tab-pane-inner" :key="renderKey">
          <div class="inspection-details-content">
            <div class="buttons">
              <button
                @click="resetSection"
                :disabled="loading || disabled"
                :class="{ 'opacity-5 cursor-not-allowed': loading || disabled }"
              >
                {{ $t("INSPECTIONS.RESET") }}
                <i class="far fa-undo" v-if="!loading"></i>
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
              </button>
              <button
                @click="setAllGoodSection"
                :disabled="loading || disabled"
                :class="{ 'opacity-5 cursor-not-allowed': loading || disabled }"
              >
                {{ $t("INSPECTIONS.ALL_GOOD") }}
                <img src="/img/sm-check.svg" alt="icon" v-if="!loading" />
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
              </button>
            </div>
            <inspection-view-inspection-section-sub-section-view
              v-for="subSection in sectionForm.subSections.filter((item) =>
                canShowElement(item, inspectionData)
              )"
              :key="subSection.id"
              :subSectionForm="subSection"
              :subSectionData="sectionModel[subSection.id]"
              :inspectionData="inspectionData"
              :disabled="disabled || loading"
              :inspection="inspection"
              :settingLoading="settingLoading"
              @subSectionChanged="
                (value) => {
                  subSectionChanged(subSection.id, value);
                }
              "
            />
          </div>
        </div>

        <div class="inspection-form-group width-1 next-button">
          <base-button
            v-if="!isFirstSection"
            size="sm"
            native-type="submit"
            :disabled="loading"
            @click="onPrevSection"
          >
            <i class="far fa-arrow-left mr-2" v-if="!loading"></i>
            <i class="fas fa-spinner fa-spin mr-2" v-if="loading"></i>
            {{ $t("INSPECTIONS.PREV_STEP") }}
          </base-button>
          <base-button
            v-if="!isLastSection"
            size="sm"
            native-type="submit"
            :disabled="loading"
            @click="onNextSection"
          >
            {{ $t("INSPECTIONS.NEXT_STEP") }}
            <i class="far fa-arrow-right" v-if="!loading"></i>
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
          </base-button>
          <base-button
            v-if="isLastSection"
            size="sm"
            native-type="submit"
            :disabled="loading"
            @click="onNextSection"
          >
            {{ $t("INSPECTIONS.END_FORM") }}
            <i class="far fa-arrow-right" v-if="!loading"></i>
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Button, Tooltip } from "element-ui";
import { INSPECTION_ACTION_NO_ACTION } from "@/constants/inspections";
import {
  INSPECTION_FORM_FIELD_TYPE_CHECK,
  INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET,
} from "@/constants/inspectionForms";
import InspectionViewInspectionSectionSubSectionView from "./InspectionViewInspectionSectionSubSectionView.vue";
import {
  canShowElement,
  getGoodFieldValue,
  isSectionTouched,
  isSectionFillComplete,
} from "@/helpers/formFieldHelper";

export default {
  name: "inspection-view-inspection-section-modal",

  components: {
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    InspectionViewInspectionSectionSubSectionView,
  },

  props: [
    "sectionForm",
    "sectionData",
    "inspectionData",
    "inspection",
    "isLastSection",
    "isFirstSection",
    "disabled",
    "loading",
  ],

  data() {
    return {
      sectionModel: cloneDeep(this.sectionData),
      canShowElement: canShowElement,
      settingLoading: false,
      renderKey: 0,
      isSectionTouched: isSectionTouched,
      isSectionFillComplete: isSectionFillComplete,
      isPageTouched: false,
      firstScroll: true,
    };
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    getSectionScore(section) {
      return this.inspection.sectionsScores[section.id].score;
    },

    closeModal() {
      this.$emit("onCloseModal");
    },

    changeSection(section) {
      this.$emit("onChangeSection", section);
      this.$refs.sectionModalBody.scrollTop = 0;
      this.firstScroll = true;
    },

    subSectionChanged(subSectionId, value) {
      this.sectionModel = {
        ...cloneDeep(this.sectionModel),
        [subSectionId]: value,
      };
      this.$emit("sectionChanged", cloneDeep(this.sectionModel));
    },

    async onNextSection() {
      this.$emit("onNextSection");
      this.$refs.sectionModalBody.scrollTop = 0;
      this.firstScroll = true;
    },

    async onPrevSection() {
      this.$emit("onPrevSection");
      this.$refs.sectionModalBody.scrollTop = 0;
      this.firstScroll = true;
    },

    async resetSection() {
      this.settingLoading = true;
      swal.fire({
        title: this.$t("INSPECTIONS.RESET"),
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      /** refresh model */
      const sectionModelData = cloneDeep(this.sectionModel);
      for (const subSectionModelData of Object.values(sectionModelData)) {
        for (const fieldModelData of Object.values(subSectionModelData)) {
          fieldModelData.value = null;
          fieldModelData.touched = false;
          fieldModelData.images = [];
          fieldModelData.comment = null;
          fieldModelData.action = INSPECTION_ACTION_NO_ACTION;
        }
      }
      this.sectionModel = sectionModelData;
      this.$emit("sectionChanged", cloneDeep(this.sectionModel));

      await this.$store.dispatch("inspections/resetSection", {
        inspectionId: this.inspection.id,
        data: {
          sections: [
            { type: "inspection-form-sections", id: this.sectionForm.id },
          ],
        },
      });

      this.renderKey++;
      await new Promise((resolve) => setTimeout(resolve, 1000));

      this.settingLoading = false;
      swal.close();
    },

    async setAllGoodSection() {
      this.settingLoading = true;
      swal.fire({
        title: this.$t("INSPECTIONS.ALL_GOOD"),
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      /** refresh model */
      const sectionModelData = cloneDeep(this.sectionModel);
      for (const subSectionModelData of Object.values(sectionModelData)) {
        for (const fieldModelData of Object.values(subSectionModelData)) {
          fieldModelData.touched = true;
          fieldModelData.comment = "";
          fieldModelData.action = INSPECTION_ACTION_NO_ACTION;
          if (
            [
              INSPECTION_FORM_FIELD_TYPE_STATE_WIDGET,
              INSPECTION_FORM_FIELD_TYPE_CHECK,
            ].includes(fieldModelData.inspectionFormSectionField.field_type)
          ) {
            fieldModelData.value = getGoodFieldValue(
              fieldModelData.inspectionFormSectionField
            );
          }
        }
      }
      this.sectionModel = sectionModelData;
      this.$emit("sectionChanged", cloneDeep(this.sectionModel));

      await this.$store.dispatch("inspections/setSectionGood", {
        inspectionId: this.inspection.id,
        data: {
          sections: [
            { type: "inspection-form-sections", id: this.sectionForm.id },
          ],
        },
      });

      this.renderKey++;
      await new Promise((resolve) => setTimeout(resolve, 1000));

      this.settingLoading = false;
      swal.close();
    },

    scrollToFirstUntouchedField() {
      for (const subSectionModelData of Object.values(this.sectionModel)) {
        for (const fieldModelData of Object.values(subSectionModelData)) {
          if (
            fieldModelData.inspectionFormSectionField.field_type !=
            INSPECTION_FORM_FIELD_TYPE_CHECK
          ) {
            if (!fieldModelData.touched) {
              if (this.firstScroll && !this.settingLoading) {
                this.$scrollTo(
                  `#field_${fieldModelData.inspectionFormSectionField.id}`,
                  {
                    container:
                      ".tab-pane.active .resizable-wrapper-content-body",
                    duration: 500,
                    offset: -1500,
                  }
                );
                this.firstScroll = false;
                return;
              }
            }
          }
        }
      }
    },
  },

  mounted() {},

  watch: {
    sectionData(sectionData) {
      this.sectionModel = cloneDeep(this.sectionData);
      setTimeout(
        function () {
          this.scrollToFirstUntouchedField();
        }.bind(this),
        1000
      );
    },
  },
};
</script>
