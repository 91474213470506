<template>
  <div>
    <div class="container-fluid step-content-body">
      <vehicle-view-global :vehicle="inspection.vehicle">
        <template #header> <span></span> </template>
      </vehicle-view-global>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import InspectionFormVehicleStep from "./InspectionFormVehicleStep.vue";
import {
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_COMPLETED,
} from "@/constants/inspections";
import { isEqual } from "lodash";
import swal from "sweetalert2";
import VehicleRefStandardized from "@/components/VehicleRefStandardized.vue";
import VehicleViewGlobal from "@/views/Pages/VehiclesModule/VehicleManagement/partials/VehicleViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    VehicleViewGlobal,
  },

  mixins: [alertLeave],

  props: ["inspection", "formErrors", "loading"],

  data() {
    const inspectionData = cloneDeep(this.inspection);
    const errors = cloneDeep(this.formErrors);
    const isLoading = this.loading ?? false;
    return {
      inspectionData,
      isLoading,
      errors,
    };
  },

  created() {},

  computed: {
    canEditInspection() {
      return (
        ![INSPECTION_STATUS_COMPLETED, INSPECTION_STATUS_CANCELED].includes(
          this.inspectionData.status
        ) &&
        (this.$currentUserCan(this.$permissions.PERM_EDIT_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS))
      );
    },
  },

  methods: {
    async handleSubmit() {
      if (!this.canEditInspection) {
        return;
      }

      this.errors = null;
      this.isLoading = true;
      swal.showLoading();

      try {
        await this.handleVehicleFormSubmit(this.inspectionData.vehicle);

        const inspectionData = cloneDeep(this.inspectionData);

        delete inspectionData.inspectionForm;

        delete inspectionData.request;

        delete inspectionData.customer;

        delete inspectionData.approver;

        delete inspectionData.customer;

        this.$emit("updateInspection", inspectionData);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.errors = error.response?.data?.errors;
        swal.close();
      } finally {
        this.isLoading = false;
      }
    },

    async handleVehicleFormSubmit(vehicleData) {
      vehicleData = { ...this.inspectionData.vehicle, ...vehicleData };
      let vehicle = null;
      if (this.$idExist(vehicleData?.id)) {
        delete vehicleData.organization;
        await this.$store.dispatch("vehicles/update", vehicleData);
        vehicle = await this.$store.getters["vehicles/vehicle"];
      } else {
        delete vehicleData.id;
        await this.$store.dispatch("vehicles/add", vehicleData);
        vehicle = await this.$store.getters["vehicles/vehicle"];
      }

      this.inspectionData.vehicle = {
        ...this.inspectionData.vehicle,
        ...vehicle,
      };
      this.inspectionData.organization = {
        ...this.inspectionData.organization,
        ...vehicle.organization,
      };
      this.inspectionData.allowedLocations = cloneDeep(
        vehicle.allowedLocations
      );

      this.inspectionData.gallery = vehicle?.gallery;
      this.inspectionData.odometer = vehicle?.odometer;
      this.inspectionData.registration_number = vehicle?.registration_number;
      this.inspectionData.color = vehicle?.color;
    },

    onViewInspection(inspection) {
      this.$emit("onViewInspection", inspection);
    },

    showVehicle() {
      this.$router.push(this.$objectViewRoute(this.inspection.vehicle));
    },
  },

  watch: {
    inspection() {
      if (!isEqual(this.inspectionData, this.inspection)) {
        this.inspectionData = cloneDeep(this.inspection);
      }
    },
    formErrors() {
      this.errors = cloneDeep(this.formErrors);
    },
  },
};
</script>
