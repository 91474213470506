<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!inspection">
      <span class="loader"></span>
    </span>
    <inspection-form
      v-if="inspection"
      :formErrors="formErrors"
      :inspectionData="inspection"
      :loading="loading"
      @formChanged="
        () => {
          alertLeave = true;
          get();
        }
      "
      @inspectionSubmitted="handleSubmit"
      @onViewInspection="(inspection) => onViewInspection(inspection)"
      @onCloseInspectionModal="onCloseInspectionModal"
    />
  </div>
</template>

<script>
import InspectionForm from "../partials/InspectionForm.vue";
import alertLeave from "@/mixins/alert-leave-mixin";
import {
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_COMPLETED,
} from "@/constants/inspections";

export default {
  layout: "DashboardLayout",

  components: { InspectionForm },

  mixins: [alertLeave],

  props: {
    inspectionId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      inspection: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  computed: {
    canEditInspection() {
      return (
        ![INSPECTION_STATUS_CANCELED, INSPECTION_STATUS_COMPLETED].includes(
          this.inspection?.status
        ) &&
        (this.$currentUserCan(this.$permissions.PERM_EDIT_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS))
      );
    },
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("inspections/get", this.inspectionId);
        this.inspection = this.$store.getters["inspections/inspection"];
        if (this.canEditInspection) {
          this.$emit("inspectionLoaded", this.inspection);
        } else {
          this.$emit("onViewInspection", this.inspection);
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit(inspectionData) {
      this.loading = true;
      try {
        await this.$store.dispatch("inspections/update", inspectionData);

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_UPDATED"),
        });

        const inspection = await this.$store.getters["inspections/inspection"];

        this.$emit("onEditInspection", inspection);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response?.data?.errors;
      } finally {
        this.loading = false;
      }
    },

    async onViewInspection(inspection = null) {
      if (!inspection) {
        await this.get();
        inspection = this.inspection;
      }

      this.$emit("onViewInspection", inspection);
    },

    onCloseInspectionModal() {
      this.$emit("onCloseInspectionModal");
    },
  },
};
</script>
