<template>
  <modal :show.sync="opened" :modal-classes="modalClasses">
    <template slot="header">
      <div class="header">
        <div class="header-left">
          <span> {{ header ?? $t("COMMON.SHARE") }} </span>
        </div>
        <div class="header-right">
          <button @click.prevent="opened = false">
            <i class="fal fa-times"></i>
          </button>
        </div>
      </div>
    </template>
    <p :class="messageClasses">
      <base-input
        v-model="email"
        :name="$t('COMMON.EMAIL')"
        :label="`${$t('COMMON.EMAIL')} (*)`"
        :placeholder="`${$t('COMMON.EMAIL')}`"
      />
      <validation-error :errors="apiValidationErrors.email" />
    </p>
    <template slot="footer">
      <Button class="no" @click.prevent="opened = false">
        {{ cancelButtonText ?? $t("COMMON.CANCEL") }}
      </Button>
      <Button class="yes" @click.prevent="submit">
        <i class="fas fa-spinner fa-spin mr-1" v-if="loading"></i>
        {{ $t("COMMON.SEND") }}
      </Button>
    </template>
  </modal>
</template>

<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";

export default {
  name: "share-modal",

  components: { ValidationError },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    modalClasses: {
      type: String,
      default: "modal-secondary valid",
    },
    emailValue: {
      type: String | null,
      default: null,
    },
    header: {
      type: String,
      default: null,
    },
    formErrors: {
      type: [Object, Array],
      default: null,
    },
    messageClasses: {
      type: String,
      default: "",
    },
    cancelButtonText: {
      type: String | null | undefined,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [formMixin],

  data() {
    const opened = this.open;
    const email = this.emailValue;

    return {
      opened,
      email,
    };
  },

  methods: {
    submit() {
      this.$emit("submit", this.email);
    },
  },

  watch: {
    opened(value, oldValue) {
      // if (oldValue && !value) {
      //   this.cancelAction();
      // }
      this.$emit("update:open", value);
    },
    open(value) {
      this.opened = value;
      if (!this.open) {
        // this.email = null;
      }
    },
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    emailValue(value) {
      this.email = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
