var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resizable-modal",rawName:"v-resizable-modal",value:('INSPECTION_DETAILS_VIEW_SECTION'),expression:"'INSPECTION_DETAILS_VIEW_SECTION'"}],staticClass:"resizable-wrapper-content",on:{"onCloseModal":_vm.closeModal}},[(false)?_c('button',{staticClass:"btn close",on:{"click":_vm.scrollToFirstUntouchedField}}):_vm._e(),_c('div',{staticClass:"resizable-wrapper-content-header"},[_c('div',{staticClass:"resizable-wrapper-content-header-left"},[_c('h1',[_vm._v(_vm._s(_vm.sectionForm.name))])]),_c('div',{staticClass:"resizable-wrapper-content-header-right"},[(_vm.loading)?_c('div',{staticClass:"custom-loading"},[_c('div',{staticClass:"custom-loading-icon"}),_c('p',[_vm._v(_vm._s(_vm.$t("COMMON.LOADING")))])]):_vm._e(),_vm._m(0),_c('button',{staticClass:"btn close",on:{"click":_vm.closeModal}},[_c('i',{staticClass:"fal fa-times"})])])]),_c('div',{ref:"sectionModalBody",staticClass:"resizable-wrapper-content-body"},[_c('div',{staticClass:"inspection-details"},[_c('div',{staticClass:"inspection-details-buttons"},_vm._l((_vm.inspection.inspectionForm.inspectionFormSections.filter(
            (item) => _vm.canShowElement(item, _vm.inspectionData)
          )),function(section){return _c('base-button',{key:section.id,class:{ active: section.id == _vm.sectionForm.id },attrs:{"disabled":_vm.settingLoading || _vm.loading,"title":section.name,"id":section.id},on:{"click":function($event){return _vm.changeSection(section)}}},[_c('i',{staticClass:"fal fa-camera"}),_c('span',[_c('el-tooltip',{staticClass:"item",attrs:{"content":section.name,"placement":"top-center"}},[(section.icon)?_c('img',{attrs:{"src":section.icon,"alt":section.name}}):_vm._e()])],1),(_vm.isSectionFillComplete(section, _vm.inspectionData))?_c('div',{staticClass:"check"},[_c('i',{staticClass:"fal fa-check"})]):_vm._e(),(
              _vm.isSectionTouched(section, _vm.inspectionData) &&
              !_vm.isSectionFillComplete(section, _vm.inspectionData)
            )?_c('div',{staticClass:"bad"},[_c('i',{staticClass:"fal fa-times"})]):_vm._e(),(_vm.isSectionTouched(section, _vm.inspectionData))?_c('div',{staticClass:"section-score"},[_vm._v(" "+_vm._s(_vm.getSectionScore(section))+"% ")]):_vm._e()])}),1),_c('div',{key:_vm.renderKey,staticClass:"tab-pane-inner"},[_c('div',{staticClass:"inspection-details-content"},[_c('div',{staticClass:"buttons"},[_c('button',{class:{ 'opacity-5 cursor-not-allowed': _vm.loading || _vm.disabled },attrs:{"disabled":_vm.loading || _vm.disabled},on:{"click":_vm.resetSection}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.RESET"))+" "),(!_vm.loading)?_c('i',{staticClass:"far fa-undo"}):_vm._e(),(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()]),_c('button',{class:{ 'opacity-5 cursor-not-allowed': _vm.loading || _vm.disabled },attrs:{"disabled":_vm.loading || _vm.disabled},on:{"click":_vm.setAllGoodSection}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.ALL_GOOD"))+" "),(!_vm.loading)?_c('img',{attrs:{"src":"/img/sm-check.svg","alt":"icon"}}):_vm._e(),(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()])]),_vm._l((_vm.sectionForm.subSections.filter((item) =>
              _vm.canShowElement(item, _vm.inspectionData)
            )),function(subSection){return _c('inspection-view-inspection-section-sub-section-view',{key:subSection.id,attrs:{"subSectionForm":subSection,"subSectionData":_vm.sectionModel[subSection.id],"inspectionData":_vm.inspectionData,"disabled":_vm.disabled || _vm.loading,"inspection":_vm.inspection,"settingLoading":_vm.settingLoading},on:{"subSectionChanged":(value) => {
                _vm.subSectionChanged(subSection.id, value);
              }}})})],2)]),_c('div',{staticClass:"inspection-form-group width-1 next-button"},[(!_vm.isFirstSection)?_c('base-button',{attrs:{"size":"sm","native-type":"submit","disabled":_vm.loading},on:{"click":_vm.onPrevSection}},[(!_vm.loading)?_c('i',{staticClass:"far fa-arrow-left mr-2"}):_vm._e(),(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin mr-2"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.PREV_STEP"))+" ")]):_vm._e(),(!_vm.isLastSection)?_c('base-button',{attrs:{"size":"sm","native-type":"submit","disabled":_vm.loading},on:{"click":_vm.onNextSection}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.NEXT_STEP"))+" "),(!_vm.loading)?_c('i',{staticClass:"far fa-arrow-right"}):_vm._e(),(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()]):_vm._e(),(_vm.isLastSection)?_c('base-button',{attrs:{"size":"sm","native-type":"submit","disabled":_vm.loading},on:{"click":_vm.onNextSection}},[_vm._v(" "+_vm._s(_vm.$t("INSPECTIONS.END_FORM"))+" "),(!_vm.loading)?_c('i',{staticClass:"far fa-arrow-right"}):_vm._e(),(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()]):_vm._e()],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn expand"},[_c('i',{staticClass:"fal fa-expand-arrows"})])
}]

export { render, staticRenderFns }