<template>
  <div class="elite-tabs-wrapper-content">
    <div class="global-header">
      <h3>{{ inspection.code }} - {{ $t("COMMON.REPAIR") }}</h3>
      <div class="buttons">
        <button class="edit" @click="showRepair" v-if="canShowRepair">
          <span> {{ $t("REPAIRS.SHOW_REPAIR") }} </span>
        </button>
      </div>
    </div>
    <repair-view-global
      class-name=""
      :repair="inspection.repair"
      :view-header="false"
      show-code
    />
  </div>
</template>

<script>
import RepairViewGlobal from "../../RepairManagement/partials/RepairViewGlobal.vue";

export default {
  name: "inspection-view-repair",

  components: { RepairViewGlobal },

  props: ["inspection"],

  data() {
    return {};
  },

  computed: {
    canShowRepair: function () {
      return (
        !!this.inspection.repair &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_MODULE_REPAIRS) &&
        this.$currentUserCan(this.$permissions.PERM_VIEW_REPAIRS)
      );
    },
  },

  created() {},

  methods: {
    showRepair() {
      this.$router.push(this.$objectViewRoute(this.inspection.repair));
    },
  },

  mounted() {},

  watch: {},
};
</script>
