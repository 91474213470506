<template>
  <div class="" v-if="canEditInspection">
    <!-- <div class="add-inspection-steps-content" v-if="canAssignInspection">
      <div class="steps-content-4">
        <div class="step-content-body pb-4">
          <inspection-form-assignation-step
            :inspection-data="inspection"
            :role.sync="role"
            :users.sync="users"
          />
        </div>
      </div>
    </div>
    <div class="inspection-form-title">
      <h3>{{ $t("INSPECTIONS.INSPECTION_FORM") }}</h3>
    </div>

    <div class="inspection-form-divider" v-if="canAssignInspection" />
    <div class="d-md-flex align-items-center" v-if="canEditInspectionForm">
      <h5 class="mr-3 mb-0">
        {{ $t("INSPECTIONS.INSPECTION_FORMS_SELECTOR") }} (*):
      </h5>
      <inspection-form-selector
        :inspectionForm="inspection.inspectionForm?.id"
        :filterable="true"
        :showAll="false"
        :filterOrganization="inspection.organization?.id"
        class="flex-grow-1"
        :filter-fuel-types="inspection.vehicle?.fuel_type"
        @inspectionFormChanged="
          (inspectionFormId) => {
            inspection.inspectionForm.id = inspectionFormId;
          }
        "
      />
    </div>
    <div class="form">
      <div class="form-group edit">
        <button
          type="button"
          :disabled="loading || !canSubmit"
          :class="{ 'opacity-4 cursor-not-allowed': loading || !canSubmit }"
          @click.prevent="handleSubmit"
        >
          {{ $t("COMMON.UPDATE") }}
          <i class="far fa-sync" :class="{ 'fa-spin': loading }"></i>
        </button>
      </div>
    </div> -->
    <div class="form">
      <div class="form-group" v-if="canEditInspectionForm">
        <inspection-form-selector
          :inspectionForm="inspection.inspectionForm?.id"
          :filterable="true"
          :showAll="false"
          :filterOrganization="inspection.organization?.id"
          class="flex-grow-1"
          :filter-fuel-types="inspection.vehicle?.fuel_type"
          @inspectionFormChanged="
            (inspectionFormId) => {
              inspection.inspectionForm.id = inspectionFormId;
            }
          "
        />
      </div>
      <div class="form-group" v-if="canAssignInspection">
        <user-selector
          :allowNone="false"
          :filterOrganization="inspection.organization.id ?? null"
          :filterPermission="$permissions.PERM_START_INSPECTIONS"
          :filterable="true"
          :showAll="false"
          :user="user"
          @userChanged="(selectedUser) => (user = selectedUser)"
        />
      </div>
      <div class="form-group edit">
        <button
          type="button"
          :disabled="loading || !canSubmit"
          :class="{ 'opacity-4 cursor-not-allowed': loading || !canSubmit }"
          @click.prevent="handleSubmit"
        >
          {{ $t("COMMON.UPDATE") }}
          <i class="far fa-sync" :class="{ 'fa-spin': loading }"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InspectionFormSelector from "@/components/InspectionFormSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import InspectionFormAssignationStep from "./InspectionFormAssignationStep.vue";
import { cloneDeep } from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Option, Select } from "element-ui";

export default {
  name: "inspection-view-inspection-edit-inspection",

  components: {
    InspectionFormSelector,
    InspectionFormAssignationStep,
    UserSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [requestErrorMixin],

  props: {
    inspectionData: {
      type: Object,
      required: true,
    },
  },

  data() {
    const inspection = cloneDeep(this.inspectionData);
    const users = inspection?.assignedToUsers?.map((user) => user.id) ?? [];
    const user = inspection?.assignedToUsers[0]?.id ?? null;
    const usersBackup =
      inspection?.assignedToUsers?.map((user) => user.id) ?? [];
    const role = inspection?.assignedToRoles[0]?.id ?? "-1";
    const roleBackup = inspection?.assignedToRoles[0]?.id ?? "-1";

    return {
      inspection,
      role,
      users,
      user,
      usersBackup,
      roleBackup,
      loading: false,
    };
  },

  computed: {
    isRoleAssignment: function () {
      return this.role != "-1";
    },

    canSubmit: function () {
      return true;
    },
    inspectionFormChanged: function () {
      return (
        this.inspection.inspectionForm.id !=
          this.inspectionData.inspectionForm.id &&
        this.$idExist(this.inspection.inspectionForm.id)
      );
    },
    canAssignInspection: function () {
      return (
        this.$currentUserCan(this.$permissions.PERM_VIEW_ANY_USERS) &&
        this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS)
      );
    },
    canEditInspectionForm: function () {
      return this.$currentUserCan(
        this.$permissions.PERM_VIEW_ANY_INSPECTION_FORMS
      );
    },
    canEditInspection: function () {
      return this.canAssignInspection || this.canEditInspectionForm;
    },
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true;

        if (this.canAssignInspection) {
          await this.assignInspection();
        }

        if (this.inspectionFormChanged) {
          await this.updateInspection();
        }

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTIONS.INSPECTION_UPDATED"),
        });
        this.$emit("onViewInspection");
      } catch (error) {
        console.log({ error });
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },
    async assignInspection() {
      await this.$store.dispatch("inspections/assign", {
        inspectionId: this.inspection.id,
        data: { users: [this.user] },
      });
    },

    async updateInspection() {
      const inspectionData = cloneDeep(this.inspection);
      await this.$store.dispatch("inspections/update", inspectionData);
    },
  },
};
</script>

<style lang="scss" scoped></style>
