var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[(!_vm.inspection)?_c('span',{staticClass:"resize-loading"},[_c('span',{staticClass:"loader"})]):_c('div',{staticClass:"elite-tabs-wrapper"},[_c('tabs',{staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper tabs-wrapper-nav","tabNavClasses":"nav elite-tabs","value":_vm.getActiveTab()},on:{"onTabChanged":_vm.tabChanged}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-list"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.GLOBAL"))+" ")]),_c('inspection-view-global',{attrs:{"inspection":_vm.inspection,"loading":_vm.loading},on:{"inspectionUpdated":_vm.inspectionUpdated,"onCancelInspection":_vm.cancelInspection,"onEditInspection":_vm.editInspection,"onShareInspection":_vm.shareInspection}})],1),_c('tab-pane',{attrs:{"title":"images","id":"2","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-image"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.PICTURES"))+" ")]),_c('inspection-view-images',{attrs:{"inspection":_vm.inspection}})],1),(_vm.canViewInspectionTab)?_c('tab-pane',{attrs:{"title":"inspection","id":"3","active":true}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-clipboard-check"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.INSPECTION"))+" ")]),_c('div',{staticClass:"tab-pane-inner"},[_c('inspection-view-inspection',{attrs:{"loading":_vm.inspectionLoading || _vm.loading,"inspection":_vm.inspection},on:{"inspectionUpdated":_vm.inspectionUpdated,"onStartInspection":_vm.startInspection,"onTakeOverInspection":_vm.takeOverInspection,"onViewInspection":_vm.onViewInspection}})],1)]):_vm._e(),(
          !!_vm.inspection.estimation &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTIMATIONS)
        )?_c('tab-pane',{attrs:{"title":"estimation","id":"4","active":true}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"far fa-usd-circle"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.ESTIMATION"))+" ")]),_c('div',{staticClass:"tab-pane-inner"},[_c('inspection-view-estimate',{attrs:{"loading":_vm.inspectionLoading || _vm.loading,"inspection":_vm.inspection}})],1)]):_vm._e(),(
          !!_vm.inspection.repair &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_REPAIRS)
        )?_c('tab-pane',{attrs:{"title":"repair","id":"5","active":true}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-wrench"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.REPAIR"))+" ")]),_c('div',{staticClass:"tab-pane-inner"},[_c('inspection-view-repair',{attrs:{"loading":_vm.inspectionLoading || _vm.loading,"inspection":_vm.inspection}})],1)]):_vm._e(),(_vm.canViewVehicleTab() && _vm.$idExist(_vm.inspection.vehicle?.id))?_c('tab-pane',{attrs:{"active":false,"id":"6","title":"vehicle"}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-car"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.VEHICLE"))+" ")]),_c('div',{staticClass:"tab-pane-inner"},[_c('inspection-view-vehicle',{attrs:{"inspection":_vm.inspection,"loading":_vm.inspectionLoading,"form-errors":_vm.formErrors},on:{"inspectionUpdated":_vm.inspectionUpdated,"updateInspection":_vm.handleSubmit,"onViewInspection":_vm.onViewInspection}})],1)]):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CUSTOMERS) &&
          !!_vm.inspection.customer
        )?_c('tab-pane',{attrs:{"title":"client","id":"7","active":false}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-user"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.CUSTOMER"))+" ")]),_c('div',{staticClass:"tab-pane-inner"},[_c('inspection-view-customer',{attrs:{"inspection":_vm.inspection,"loading":_vm.inspectionLoading,"form-errors":_vm.formErrors},on:{"updateInspection":_vm.handleSubmit}})],1)]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_FILES))?_c('tab-pane',{attrs:{"title":"files","id":"998"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"far fa-file-alt"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.FILES"))+" ")]),_c('div',{staticClass:"elite-tabs-wrapper-content"},[_c('list-file-component',{attrs:{"object":_vm.inspection}})],1)]):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOGS))?_c('tab-pane',{attrs:{"title":"logs","id":"999"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-history"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.LOGS"))+" ")]),_c('inspection-view-logs',{attrs:{"inspection":_vm.inspection}})],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }