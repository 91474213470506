<template>
  <modal :show="true" modal-classes="modal-secondary verify">
    <template slot="header">
      <div class="header">
        <div class="header-left">
          <i class="fas fa-exclamation-triangle"></i>
          <span>Important</span>
        </div>
        <div class="header-right">
          <button @click="cancel">
            <i class="fal fa-times"></i>
          </button>
        </div>
      </div>
    </template>
    <p>{{ $t("INSPECTIONS.REMINDERS_ALERTS") }}</p>
    <div class="checkbox-list">
      <el-checkbox-group v-model="remindersModel">
        <el-checkbox
          v-for="reminder in inspection.inspectionForm.reminders"
          :key="reminder.id"
          :value="reminder.id"
          :label="reminder.id"
        >
          <div v-html="reminder.excerpt"></div>
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <template slot="footer">
      <Button class="no" @click="cancel">{{ $t("COMMON.CANCEL") }}</Button>
      <Button class="yes" @click="continueStep">
        {{ $t("INSPECTIONS.SIGN_AND_SEND") }}
      </Button>
    </template>
  </modal>
</template>

<script>
import swal from "sweetalert2";
import { ref } from "vue";
import { Button, Checkbox, CheckboxGroup } from "element-ui";

export default {
  name: "inspection-view-inspection-reminders",

  components: {
    [Button.name]: Button,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
  },

  props: ["inspection"],

  data() {
    return {
      remindersModel: ref([]),
    };
  },

  computed: {},

  created() {},

  mounted() {
    this.remindersModel = ref([]);
  },

  methods: {
    continueStep() {
      if (!this.canContinue()) {
        swal.fire({
          title: this.$t("INSPECTIONS.YOU_MUST_CHECK_ALL_BOXES"),
          icon: "warning",
          showCancelButton: false,
          showConfirmButton: true,
        });
        return;
      }
      this.$emit("onContinueStep");
    },

    canContinue() {
      for (const reminder of this.inspection.inspectionForm.reminders) {
        if (!this.remindersModel.includes(reminder.id)) {
          return false;
        }
      }
      return true;
    },

    cancel() {
      this.$emit("onCloseModal");
    },
  },

  watch: {},
};
</script>
