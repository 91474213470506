<template>
  <div>
    <vehicle-form
      :loading="loading"
      :disable="disabled"
      :vehicleData="inspection.vehicle"
      :formErrors="formErrorsValue"
      @vehicleSubmitted="handleVehicleFormSubmit"
      :saveText="vehicleSaveBtn"
      @formChanged="vehicleChanged"
      :fuel-types="
        disabledInspectionForm
          ? inspection?.inspectionForm?.fuel_types ?? []
          : []
      "
      @vehicleHasInProgressInspections="vehicleHasInProgressInspections"
    >
      <template #submit-button><span></span></template>
    </vehicle-form>
    <div class="inspection-form-divider" v-if="!hiddenInspectionForm"></div>
    <div class="inspection-form-title" v-if="!hiddenInspectionForm">
      <h3>{{ $t("INSPECTIONS.INSPECTION_FORM") }}</h3>
    </div>

    <div class="inspection-form-divider" v-if="!hiddenInspectionForm" />

    <div v-if="!hiddenInspectionForm">
      <div class="d-md-flex align-items-center">
        <h5 class="mr-3 mb-0">
          {{ $t("INSPECTIONS.INSPECTION_FORMS_SELECTOR") }} (*):
        </h5>
        <inspection-form-selector
          :disabled="disabled || disabledInspectionForm"
          :inspectionForm="inspection.inspectionForm?.id ?? null"
          :filterable="true"
          :showAll="false"
          :filterOrganization="inspection.organization?.id ?? null"
          class="flex-grow-1"
          :filter-fuel-types="inspection.vehicle?.fuel_type"
          @inspectionFormChanged="
            (inspectionFormId) => {
              inspection.inspectionForm.id = inspectionFormId;
            }
          "
        />
        <validation-error :errors="apiValidationErrors.sku" />
      </div>
      <validation-error :errors="apiValidationErrors.inspectionForm" />
    </div>

    <div class="inspection-form-divider" v-if="!hiddenInspectionForm"></div>

    <div class="inspection-form-title" v-if="!hiddenInspectionForm">
      <h3>{{ $t("INSPECTIONS.EXCERPT") }}</h3>
    </div>

    <div class="inspection-form-divider" v-if="!hiddenInspectionForm"></div>

    <div v-if="!hiddenInspectionForm" class="mt-3">
      <div class="inspection-form-group width-1">
        <base-input type="textarea" :placeholder="$t('INSPECTIONS.EXCERPT')">
          <html-editor v-model="inspection.excerpt" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.sku" />
      </div>
      <validation-error :errors="apiValidationErrors.inspectionForm" />
    </div>
    <inspection-confirmation-modal
      :confirm-action="showOtherExistingInspection"
      :cancel-action="closeModal"
      :confirm-button-text="$t('INSPECTIONS.SHOW_INSPECTION')"
      :cancel-button-text="$t('COMMON.OK')"
      :loading="loading"
      :message="$t('INSPECTIONS.INSPECTION_ALREADY_EXIST')"
      :open.sync="showVehicleInspectionExistAlertModal"
      modal-classes="modal-secondary validate"
    />
  </div>
</template>

<script>
import _, { cloneDeep } from "lodash";
import VehicleForm from "@/views/Pages/VehiclesModule/VehicleManagement/partials/VehicleForm.vue";
import InspectionFormSelector from "@/components/InspectionFormSelector.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import defaultVehicle from "@/views/Pages/VehiclesModule/VehicleManagement/defaultVehicle";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

export default {
  name: "inspection-form-vehicle-step",

  components: {
    VehicleForm,
    InspectionFormSelector,
    ValidationError,
    InspectionConfirmationModal,
    HtmlEditor,
  },

  mixins: [formMixin],

  props: {
    inspectionData: { type: Object },
    formErrors: {
      type: [Object, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledInspectionForm: {
      type: Boolean,
      default: false,
    },
    hiddenInspectionForm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const inspection = {
      ...this.inspectionData,
      vehicle: {
        ...defaultVehicle,
        ...this.inspectionData.vehicle,
        organization: {
          ...this.inspectionData.vehicle?.organization,
          ...this.inspectionData.organization,
        },
      },
    };

    const formErrorsValue = cloneDeep(this.formErrors);

    const backupInspection = cloneDeep(inspection);
    return {
      inspection,
      backupInspection,
      loading: false,
      formErrorsValue,
      showVehicleInspectionExistAlertModal: false,
      existOtherInspectionId: null,
    };
  },

  computed: {
    vehicleSaveBtn() {
      if (!this.inspection.vehicle?.id || isNaN(this.inspection.vehicle.id)) {
        return this.$t("VEHICLES.ADD_VEHICLE");
      } else {
        return this.$t("VEHICLES.EDIT_VEHICLE");
      }
    },
  },

  methods: {
    async handleVehicleFormSubmit(vehicleData) {
      this.loading = true;
      vehicleData = { ...this.inspection.vehicle, ...vehicleData };
      let vehicle = null;
      try {
        if (this.$idExist(vehicleData?.id)) {
          delete vehicleData.organization;
          await this.$store.dispatch("vehicles/update", vehicleData);
          vehicle = await this.$store.getters["vehicles/vehicle"];
        } else {
          delete vehicleData.id;
          await this.$store.dispatch("vehicles/add", vehicleData);
          vehicle = await this.$store.getters["vehicles/vehicle"];
        }

        this.vehicleChanged(vehicle);

        this.inspection.gallery = vehicle?.gallery;
        this.inspection.odometer = vehicle?.odometer;
        this.inspection.odometer_unit = vehicle?.odometer_unit;
        this.inspection.registration_number = vehicle?.registration_number;
        this.inspection.color = vehicle?.color;
        console.log(this.inspection);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrorsValue = error.response?.data?.errors;
      } finally {
        this.loading = false;
      }
    },

    vehicleChanged(vehicleData) {
      this.inspection.vehicle = { ...this.inspection.vehicle, ...vehicleData };
      this.inspection.organization = {
        ...this.inspection.organization,
        ...vehicleData.organization,
      };
      this.inspection.allowedLocations = cloneDeep(
        vehicleData.allowedLocations
      );
    },
    vehicleHasInProgressInspections(inspectionIds) {
      if (!!inspectionIds && typeof inspectionIds == "object") {
        if (this.$idExist(this.inspection.id)) {
          delete inspectionIds[this.inspection.id];
        }

        if (Object.keys(inspectionIds).length > 0) {
          this.existOtherInspectionId = Object.keys(inspectionIds)[0];

          if (this.existOtherInspectionId) {
            this.showVehicleInspectionExistAlertModal = true;
          }
        }
      }
    },
    showOtherExistingInspection() {
      return this.$emit("onViewInspection", {
        id: this.existOtherInspectionId,
        type: "inspections",
      });
    },
    closeModal() {
      this.$emit("onCloseInspectionModal");
    },
  },

  watch: {
    inspection: {
      handler: function () {
        this.$emit("update:inspectionData", this.inspection);
      },
      deep: true,
    },

    inspectionData: {
      handler: function (value) {
        if (_.isEqual(value, this.inspection)) return;
        this.inspection = { ...this.inspection, ...value };
      },
      deep: true,
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
      this.formErrorsValue = cloneDeep(errors);
    },
  },
};
</script>

<style lang="scss" scoped></style>
