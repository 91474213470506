<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
    <inspection-form
      v-else
      :form-errors="formErrors"
      :inspection-data="inspection"
      :loading="loading"
      @onEditInspection="onEditInspection"
      @onViewInspection="onViewInspection"
      @onCloseInspectionModal="onCloseInspectionModal"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultInspection from "../defaultInspection";
import InspectionForm from "../partials/InspectionForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    InspectionForm,
  },

  mixins: [alertLeave],

  data() {
    let inspectionData = cloneDeep(defaultInspection);
    inspectionData = this.$fillUserOrganizationData(inspectionData);
    inspectionData.request.id = this.$route.query.request_id;
    inspectionData.customer.id = this.$route.query.customer_id;
    return {
      inspection: inspectionData,
      formErrors: null,
      loading: false,
    };
  },

  async created() {
    if (this.$route.query.vehicle_id) {
      await this.getVehicle(this.$route.query.vehicle_id);
    }
  },

  methods: {
    onEditInspection(inspection) {
      this.$emit("onEditInspection", inspection);
    },
    async onViewInspection(inspection = null) {
      if (!inspection) {
        return;
      }
      this.$emit("onViewInspection", inspection);
    },

    async getVehicle(vehicleId) {
      this.loading = true;
      try {
        await this.$store.dispatch("vehicles/get", vehicleId);
        this.inspection.vehicle = await this.$store.getters["vehicles/vehicle"];
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    onCloseInspectionModal() {
      this.$emit("onCloseInspectionModal");
    },
  },
};
</script>
