export default {
  type: "inspection-fields",
  id: null,
  value: null,
  comment: null,
  images: [],
  points: 0,
  touched: false,
  action: "NO_ACTION",
  relationshipNames: ["inspection", "inspectionFormSectionField"],
  inspection: null,
  inspectionFormSectionField: null,
};
